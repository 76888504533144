<template>
  <div>
    <v-card>
      <StandardCardHeader
        :go-to-link="'admin-payment-list'"
        :go-to-link-id="`${payment.companyId}`"
        :title="'Edit payment'"
        :button-text="'Close'"
        :show-action-button="true"
        :link-button-color="'error'"
        @action="save()"
      />
      <v-form
        ref="form"
        lazy-validation
        class="ma-0 pt-6"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="payment.value"
                :rules="[validator.required]"
                dense
                outlined
                :label="$t('Value')"
                class="w-full"
                @input="formatPrice()"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-textarea
                v-model="payment.comment"
                dense
                outlined
                :label="$t('Comment')"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <StandardCardHeader
        :go-to-link="'admin-payment-list'"
        :go-to-link-id="`${payment.companyId}`"
        :button-text="'Close'"
        :show-action-button="true"
        :link-button-color="'error'"
        @action="save()"
      />
    </v-card>

    <v-snackbar
      v-model="snackbar"
    >
      {{ $t('Data successfully saved') }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCellphoneKey,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEmailAlert,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'

import axiosIns from '@axios'
import { emailValidator, passwordValidator, required } from '@core/utils/validation'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'

export default {
  components: { StandardCardHeader },
  data() {
    const snackbarMessage = ''
    const paymentId = null
    const snackbar = false
    const loading = true
    const options = {}
    const payment = {
      id: '',
      value: '',
      comment: '',
      companyId: '',
    }

    return {
      dateMenu: false,
      snackbarMessage,
      paymentId,
      snackbar,
      options,
      loading,
      payment,

      icons: {
        mdiCellphoneKey,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiEmailAlert,
      },

      validator: {
        emailValidator,
        passwordValidator,
        required,
      },
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    formatPrice() {
      this.payment.value = (+this.payment.value.replace(/,/g, '')).toLocaleString('en-GB', { maximumFractionDigits: 2 })
    },
    getDataFromApi() {
      axiosIns.get(`/admin/payments/${this.$route.params.id}`)
        .then(res => {
          const { payment } = res.data

          this.paymentId = payment.id
          this.payment.value = (+payment.value * 1.2).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          this.payment.comment = payment.comment
          this.payment.companyId = payment.company_id
        })
        .catch()
    },
    save() {
      if (this.$refs.form.validate()) {
        const payment = {
          value: +this.payment.value.replace(/,/g, '') / 1.2,
          comment: this.payment.comment,
          company_id: this.payment.companyId,
        }

        axiosIns({
          method: this.paymentId ? 'PUT' : 'POST',
          data: payment,
          url: this.paymentId ? `/admin/payments/${this.paymentId}` : '/admin/payments',
        })
          .then(res => {
            this.snackbarMessage = this.$t('Data successfully saved')
            this.snackbar = true
            this.paymentId = res.data.payment.id
          })
          .catch(() => {
            this.snackbarMessage = this.$t('An error occurred')
            this.snackbar = true
          })
      }
    },
  },
}
</script>
